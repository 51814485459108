import { gql } from '@apollo/client';

/* Assets */
export const GET_LOCATION_PARAMETERS = gql`
	query GetLocationParameters($locationId: ID, $identifier: String) {
		locationParameters(locationId: $locationId, identifier: $identifier) {
			locationTheme
			locale
		}
	}
`;

/* External service pin code */
export const CHECK_PINCODE = gql`
	query CheckPinCode($pin: String!, $serviceId: ID!) {
		pinCode(pin: $pin, serviceId: $serviceId)
	}
`;
/* Info Tv */
export const GET_INFOTV = gql`
	query GetInfo($locationId: ID!, $floors: [String]) {
		infoTV(id: $locationId, floors: $floors) {
			locationName
			feelisScore
			co2Score
			particleScore
			tvocScore
			temperature
			lastNegFeedback
		}
	}
`;

/* Install tool */
export const GET_LOCATIONS = gql`
	query GetLocations($userId: ID!) {
		locations(userId: $userId) {
			id
			name
		}
	}
`;

export const GET_PREMISES = gql`
	query GetPremises($locationId: ID!) {
		premises(locationId: $locationId) {
			id
			name
		}
	}
`;

export const CREATE_QR = gql`
	mutation CreateQR($request: SaveQrRequest!) {
		saveQrCode(request: $request)
	}
`;

export const CHECK_CODE = gql`
	query CheckCode($identifier: String!) {
		checkCode(identifier: $identifier)
	}
`;

export const GET_QR = gql`
	query GetQrCode($identifier: String!) {
		qrCode(identifier: $identifier) {
			id
			identifier
			locationId
			locationLanguage
			premiseId
			questionnaireId
		}
	}
`;

export const FETCH_LID = gql`
	query FetchLocationId($identifier: String!) {
		qrCode(identifier: $identifier) {
			locationId
		}
	}
`;

export const GET_LOCATION_PARAMS = gql`
	query LocationParams {
		buildingTypeParametersForLocation {
			id
			name {
				en
				fi
				sv
				de
			}
			values {
				id
				name {
					en
					fi
					sv
					de
				}
			}
		}
	}
`;

export const GET_PREMISE_PARAMS = gql`
	query PremiseParams {
		buildingTypeParametersForPremise {
			id
			name {
				en
				fi
				sv
				de
			}
			values {
				id
				name {
					en
					fi
					sv
					de
				}
			}
		}
	}
`;

/* Feelis service */
export const GET_DASHBOARD = gql`
	query GetDashboard($premiseId: ID!, $locationId: ID!) {
		dashboard(premiseId: $premiseId, locationId: $locationId) {
			locationId
			locationName
			premiseId
			premiseName
			co2Score
			feelisPercentageScoreAll {
				value
				lastNegFeedback
			}
			feelisPercentageScoreMonth {
				value
				lastNegFeedback
			}
			temperature
			question {
				id
				title {
					fi
					en
					sv
					de
					fr
					es
					nl
				}
				options {
					value
					label {
						fi
						en
						sv
						de
						fr
						es
						nl
					}
				}
			}
		}
	}
`;

//external services
export const GET_EXTERNAL_SERVICES = gql`
	query ExternalServices($premiseId: ID!, $locationId: ID!) {
		externalServices(premiseId: $premiseId, locationId: $locationId) {
			servicesLocation {
				id
				name
				config
				pin
			}
			servicesPremise {
				id
				name
				config
				pin
			}
		}
	}
`;

export const GET_QUESTIONNAIRE = gql`
	query GetQuestionnaire {
		questionnaire {
			id
			title {
				fi
				en
				sv
				de
				fr
				es
				nl
			}
			questions {
				id
				title {
					fi
					en
					sv
					de
					fr
					es
					nl
				}
				options {
					label {
						fi
						en
						sv
						de
						fr
						es
						nl
					}
					value
				}
			}
			mainQuestionId
			tempQuestionId
			co2QuestionId
		}
	}
`;

export const GET_MEASUREMENTS = gql`
	query GetMeasurementsForPremise($premiseId: ID!) {
		measurements(premiseId: $premiseId) {
			temperature {
				data {
					type
					unit
					measurements {
						value
						key
						time
					}
					currentValue
				}
				question {
					id
					title {
						fi
						en
						sv
						de
						fr
						es
						nl
					}
					options {
						label {
							fi
							en
							sv
							de
							fr
							es
							nl
						}
						value
					}
				}
				temperatureScorePercentageMonth {
					percentages {
						value
						percentage
					}
					lastNegFeedback
				}
			}
			co2 {
				data {
					type
					unit
					measurements {
						value
						key
						time
					}
					currentValue
				}
				question {
					id
					title {
						fi
						en
						sv
						de
						fr
						es
						nl
					}
					options {
						label {
							fi
							en
							sv
							de
							fr
							es
							nl
						}
						value
					}
				}
				co2ScorePercentageMonth {
					percentages {
						value
						percentage
					}
					lastNegFeedback
				}
			}
		}
	}
`;
